<template>
    <div class="dashboard">
        <div class="page-title">
            <h1 class="h1">Список событий</h1>
        </div>
        <b-tabs card v-model="tabIndex">
            <b-tab title="Бизнес ивенты">
                <business-tab v-if="tabIndex === 0"/>
            </b-tab>
            <b-tab title="Форум">
                <forums-tab v-if="tabIndex === 1"/>
            </b-tab>
        </b-tabs>
        <router-link
            :to="{name: 'forum-add'}"
            class="add"
        >
            <b-icon icon="plus-circle"></b-icon>
        </router-link>
    </div>
</template>

<script>
    import ForumsTab from '@/components/forum/ForumsTab'
    import BusinessTab from '@/components/forum/BusinessTab'

    export default {
        name: 'forums',
        components: {
            BusinessTab,
            ForumsTab
        },
        data(){
            return{
                tabIndex: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-title{
        background: #fff;
        padding: 10px 20px 0;
        border-radius: 6px 6px 0 0;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
        .h1{
            margin: 0;
        }
    }
</style>
