<template>
    <div>
        <div class="wrapper">
            <div class="data-rang">
                <date-picker
                    name="filterByDate"
                    format="DD-MM-YYYY"
                    input-class="form-control"
                    v-model="filterByDate"
                    value-type="timestamp"
                    range
                    placeholder="Выберите диапазон дат и времени"
                    empty-text="Нет доступных Форумов"
                    show-empty
                ></date-picker>
            </div>
            <div class="auto-scroll-horizontal">
                <b-table
                        :busy="isBusy"
                        :filter="filterByDate"
                        :fields="fields"
                        :items="forumsProvider"
                        class="mb-0"
                        hover
                        :current-page="currentPage"
                        :per-page="perPage"
                        @row-clicked="showForumDetails"
                        :tbody-tr-class="rowClass"
                        empty-text="Нет доступных Форумов"
                        show-empty
                >

                    <template #cell(title)="data">
                        <div class="forum-td">
                            <img v-if="data.item.imagePath" :src="data.item.imagePath" alt="">
                            <draft-icon class="draft-icon" v-if="data.item.draft" :draft="data.item.draft"/>
                            {{ data.item.title }}
                        </div>
                    </template>

                    <template #cell(dateStart)="data">
                        {{ parseDate(data.item.dateStart) }}
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <forum-status :status="data.item.status"/>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div v-if="totalForums > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalForums" :per-page="perPage" v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import DraftIcon from '@/components/forum/DraftIcon'
    import parseDate from '@/mixins/parseDate'
    import ForumStatus from "@/components/forum/ForumStatus";

    export default {
        name: "ForumsTab",
        components: {
            DraftIcon,
            ForumStatus
        },
        mixins: [parseDate],
        methods: {
            ...mapActions([
                'getForumsList'
            ]),
            getDateFromTimestamp(date){
                const date_ob = new Date(date);
                const year = date_ob.getFullYear();
                const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                const day = ("0" + date_ob.getDate()).slice(-2);

                return `${day}.${month}.${year}`
            },
            showForumDetails(data){
                this.$router.push(
                    { name: 'forum-details', params: { forumId: data._id } }
                )
            },
            forumsProvider(ctx){
                const data = {
                    page: ctx.currentPage,
                    top: true
                };
                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'DESC' : 'ASC'
                }
                if(ctx.filter && ctx.filter[0] && ctx.filter[1]){
                    const dayMs = 86399999;
                    data.from = ctx.filter[0];
                    data.to = ctx.filter[1] + dayMs;
                }
                return this.getForumsList(data).then((response) => {
                    this.totalForums = response.totalCount;
                    return response.results
                });
            },
            rowClass(item){
                if(item && item.draft){
                    return 'draft'
                }
            }
        },
        data(){
            return {
                isBusy: false,
                fields: [
                    {
                        key: 'title',
                        label: 'Название',
                        sortable: true,
                        class: 'w-25'
                    }, {
                        key: 'description',
                        label: 'Описание',
                        sortable: true,
                        class: 'w-50'
                    }, {
                        key: 'dateStart',
                        label: 'Дата',
                        sortable: true,
                    }, {
                        key: 'status',
                        label: 'Статус',
                        sortable: true,
                    }
                ],
                currentPage: 1,
                perPage: 10,
                filterByDate: null,
                totalForums: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .spinner-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 320px;
    }
    .data-rang{
        position: absolute;
        top: -38px;
        right: 17px;
    }
    .draft-icon{
        margin-top: -1px;
    }
    @media screen and (max-width: 991px) {
        .data-rang{
            position: static;
            margin-bottom: 20px;
        }
    }
</style>